import Chart from 'chart.js/auto'
import ChartjsPluginStacked100 from 'chartjs-plugin-stacked100'

Chart.register(ChartjsPluginStacked100)

function intComma(num: Number): String {
  let x = num.toString()
  const pattern = /(-?\d+)(\d{3})/
  while (pattern.test(x)) x = x.replace(pattern, '$1,$2')
  return x
}

/**
 * Sets up and displays a horizontal bar chart.
 *
 * @param {CanvasRenderingContext2D} ctx - The 2D rendering context of the canvas element.
 * @param {string} year - The year associated with the data.
 * @param {object} data - The data object containing the chart data.
 * @returns {void}
 */
const HorizontalBar = function (ctx: string, title: string, data: any) {
  console.log(' --- setup horizontal bar chart ---')

  const canvas = document.getElementById(ctx)

  new Chart(canvas, {
    type: 'bar',
    data: data,
    options: {
      scaleShowLabels: false,
      responsive: true,
      indexAxis: 'y',
      scales: {
        x: { display: false },
        y: { display: false }
      },
      plugins: {
        stacked100: { enable: true, replaceTooltipLabel: false },
        title: { display: !!title, text: title },
        legend: { position: 'bottom' },
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = `${context.dataset.label}: \$${intComma(context.dataset.data)} (${context.formattedValue}%)`
              return label
            }
          }
        }
      }
    }
  })
}

const VerticalBar = function (ctx: string, title: string, data: any) {
  console.log(' --- setup pie chart ---')

  const canvas = document.getElementById(ctx)

  new Chart(canvas, {
    type: 'bar',
    data: data,
    options: {
      scaleShowLabels: false,
      responsive: true,
      indexAxis: 'y',
      scales: {
        x: {
          display: true,
          ticks: {
            // Include a dollar sign in the ticks
            callback: function (value, index, ticks) {
              return `\$${value / 1000 / 1000}M`
            }
          }
        },
        y: { display: false }
      },
      plugins: {
        title: { display: !!title, text: title },
        legend: { position: 'top' }
      }
    }
  })
}

const Pie = function (ctx: string, title: string, data: any) {
  console.log(' --- setup pie chart ---')

  const canvas = document.getElementById(ctx)

  new Chart(canvas, {
    type: 'pie',
    data: data,
    options: {
      scaleShowLabels: false,
      responsive: true,
      indexAxis: 'y',
      scales: {
        x: { display: false },
        y: { display: false }
      },
      plugins: {
        title: { display: !!title, text: title },
        legend: { position: 'bottom' }
      }
    }
  })
}

const Doughnut = function (ctx: string, title: string, data: any) {
  console.log(' --- setup pie chart ---')

  const canvas = document.getElementById(ctx)

  new Chart(canvas, {
    type: 'doughnut',
    data: data,
    options: {
      scaleShowLabels: false,
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: 1,
      indexAxis: 'y',
      scales: {
        x: { display: false },
        y: { display: false }
      },
      plugins: {
        title: { display: !!title, text: title },
        legend: { position: 'bottom' }
      }
    }
  })
}

const WorldMap = function (ctx: string, title: string, data: any) {
  console.log(' --- setup pie chart ---')

  const canvas = document.getElementById(ctx)

  new Chart(canvas, {
    type: 'doughnut',
    data: data,
    options: {
      scaleShowLabels: false,
      responsive: true,
      indexAxis: 'y',
      scales: {
        x: { display: false },
        y: { display: false }
      },
      plugins: {
        title: { display: true, text: title },
        legend: { position: 'top' }
      }
    }
  })
}

// export App to the browser/console
window.metrics = {
  HorizontalBar,
  VerticalBar,
  Pie,
  Doughnut,
  WorldMap
}
